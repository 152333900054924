<template>
  <div style="background-color: #FFFFFF;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="最近领取" name="latest">
        <el-row style="margin: 20px;">
          <el-col :span="24">
            <div class="c-container">
              <div class="c-card-box" v-for="(item, index) in latestDataList" v-bind:key="item.blCouponId + index">
                <div :class="item.blCouponRule.couponType == 0 ? 'c-card-left-yellow' : 'c-card-left-blue'">
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 0">
                    {{ item.blCouponRule.couponValue }}年VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 1">
                    {{ item.blCouponRule.couponValue }}月VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 2">
                    {{ item.blCouponRule.couponValue }}周VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 3">
                    {{ item.blCouponRule.couponValue }}日VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 1"><span
                    style="font-size: 12px">￥</span>{{ item.blCouponRule.couponValue / 100 || '-' }}
                  </div>
                  <div class="c-condition" v-if="item.blCouponRule.couponType == 0">普通会员可用</div>
                  <div class="c-condition"
                       v-if="item.blCouponRule.couponType == 1 && item.blCouponRule.fullCutDisable == 0">
                    满{{ item.blCouponRule.fullCutPrice / 100 || '-' }}可用
                  </div>
                  <div class="c-condition"
                       v-if="item.blCouponRule.couponType == 1 && item.blCouponRule.fullCutDisable == 1">无使用限制
                  </div>
                </div>
                <div class="c-card-right">
                  <div class="c-name"><strong>{{ item.blCouponRule.couponName }}</strong></div>
                  <div class="c-usable-time">{{ new Date(item.startTime).toLocaleDateString() }} -
                    {{ new Date(item.endTime).toLocaleDateString() }}
                  </div>
                  <hr width="236px" color="#E6E6E6" style="margin-left: 13px; margin-top: 5px;"/>
                  <el-popover
                    placement="top-start"
                    :width="200"
                    trigger="hover"
                  >
                    <template #reference>
                      <div class="c-description"><span>使用说明</span><span class="icon iconfont">&#xe8bc;</span></div>
                    </template>
                    <div v-for="(items, index) of item.blCouponRule.couponExplain" :key="index">
                      {{items}}
                    </div>
                  </el-popover>
                  <!--                  <div class="c-state">-->
                  <!--                    <img src="@/assets/images/used.png" height="75" width="82"-->
                  <!--                         style="position: absolute; top: 0; right: 0"/>-->
                  <!--                  </div>-->
                  <div class="c-btn" v-if="item.couponStatus == 0">
                    <el-button round plain type="primary" size="small"
                               style="position: absolute; top: 45px; right: 10px;"
                               @click="handleUseCoupon(item.blCouponId)">立即使用
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <el-empty v-if="latestDataList.length == 0" :image-size="200"></el-empty>
            <div class="ac-page">
              <el-pagination
                :hide-on-single-page="true"
                :pager-count="latestPage.pagerCount"
                background
                layout="prev, pager, next"
                @current-change="currentChangeLastest"
                :total="latestPage.total">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="已使用" name="used">
        <el-row style="margin: 20px;">
          <el-col :span="24">
            <div class="c-container">
              <div class="c-card-box" v-for="(item, index) in usedDataList" v-bind:key="item.blCouponId + index">
                <div class="c-card-left-gray">
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 0">
                    {{ item.blCouponRule.couponValue }}年VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 1">
                    {{ item.blCouponRule.couponValue }}月VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 2">
                    {{ item.blCouponRule.couponValue }}周VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 3">
                    {{ item.blCouponRule.couponValue }}日VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 1"><span
                    style="font-size: 12px">￥</span>{{ item.blCouponRule.couponValue / 100 || '-' }}
                  </div>
                  <div class="c-condition" v-if="item.blCouponRule.couponType == 0">普通会员可用</div>
                  <div class="c-condition"
                       v-if="item.blCouponRule.couponType == 1 && item.blCouponRule.fullCutDisable == 0">
                    满{{ item.blCouponRule.fullCutPrice / 100 || '-' }}可用
                  </div>
                  <div class="c-condition"
                       v-if="item.blCouponRule.couponType == 1 && item.blCouponRule.fullCutDisable == 1">无使用限制
                  </div>
                </div>
                <div class="c-card-right">
                  <div class="c-name"><strong>{{ item.blCouponRule.couponName }}</strong></div>
                  <div class="c-usable-time">{{ new Date(item.startTime).toLocaleDateString() }} -
                    {{ new Date(item.endTime).toLocaleDateString() }}
                  </div>
                  <hr width="236px" color="#E6E6E6" style="margin-left: 13px; margin-top: 5px;"/>
                  <el-popover
                    placement="top-start"
                    :width="200"
                    trigger="hover"
                  >
                    <template #reference>
                      <div class="c-description"><span>使用说明</span><span class="icon iconfont">&#xe8bc;</span></div>
                    </template>
                    <div v-for="(items, index) of item.blCouponRule.couponExplain" :key="index">
                      {{items}}
                    </div>
                  </el-popover>
                  <div class="c-state">
                    <img src="@/assets/images/used.png" height="75" width="82"
                         style="position: absolute; top: 0; right: 0"/>
                  </div>
                  <!--                  <div class="c-btn">-->
                  <!--                    <el-button round plain type="primary" size="small"-->
                  <!--                               style="position: absolute; top: 45px; right: 10px;">立即使用-->
                  <!--                    </el-button>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
            <el-empty v-if="usedDataList.length == 0" :image-size="200"></el-empty>
            <div class="ac-page">
              <el-pagination
                :hide-on-single-page="true"
                :pager-count="overduePage.pagerCount"
                background
                layout="prev, pager, next"
                @current-change="currentChangeUsed"
                :total="overduePage.total">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="已过期" name="overdue">
        <el-row style="margin: 20px;">
          <el-col :span="24">
            <div class="c-container">
              <div class="c-card-box" v-for="(item, index) in overdueDataList" v-bind:key="item.blCouponId + index">
                <div class="c-card-left-gray">
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 0">
                    {{ item.blCouponRule.couponValue }}年VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 1">
                    {{ item.blCouponRule.couponValue }}月VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 2">
                    {{ item.blCouponRule.couponValue }}周VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 0 && item.blCouponRule.valueTimeType == 3">
                    {{ item.blCouponRule.couponValue }}日VIP
                  </div>
                  <div class="c-price" v-if="item.blCouponRule.couponType == 1"><span
                    style="font-size: 12px">￥</span>{{ item.blCouponRule.couponValue / 100 || '-' }}
                  </div>
                  <div class="c-condition" v-if="item.blCouponRule.couponType == 0">普通会员可用</div>
                  <div class="c-condition"
                       v-if="item.blCouponRule.couponType == 1 && item.blCouponRule.fullCutDisable == 0">
                    满{{ item.blCouponRule.fullCutPrice / 100 || '-' }}可用
                  </div>
                  <div class="c-condition"
                       v-if="item.blCouponRule.couponType == 1 && item.blCouponRule.fullCutDisable == 1">无使用限制
                  </div>
                </div>
                <div class="c-card-right">
                  <div class="c-name"><strong>{{ item.blCouponRule.couponName }}</strong></div>
                  <div class="c-usable-time">{{ new Date(item.startTime).toLocaleDateString() }} -
                    {{ new Date(item.endTime).toLocaleDateString() }}
                  </div>
                  <hr width="236px" color="#E6E6E6" style="margin-left: 13px; margin-top: 5px;"/>
                  <el-popover
                    placement="top-start"
                    :width="200"
                    trigger="hover"
                  >
                    <template #reference>
                      <div class="c-description"><span>使用说明</span><span class="icon iconfont">&#xe8bc;</span></div>
                    </template>
                    <div v-for="(items, index) of item.blCouponRule.couponExplain" :key="index">
                      {{items}}
                    </div>
                  </el-popover>
                  <div class="c-state">
                    <img src="@/assets/images/overdue.png" height="75" width="82"
                         style="position: absolute; top: 0; right: 0"/>
                  </div>
                  <!--                  <div class="c-btn">-->
                  <!--                    <el-button round plain type="primary" size="small"-->
                  <!--                               style="position: absolute; top: 45px; right: 10px;">立即使用-->
                  <!--                    </el-button>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
            <el-empty v-if="overdueDataList.length == 0" :image-size="200"></el-empty>
            <div class="ac-page">
              <el-pagination
                :hide-on-single-page="true"
                :pager-count="overduePage.pagerCount"
                background
                layout="prev, pager, next"
                @current-change="currentChangeOverdue"
                :total="overduePage.total">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { getCouponsByModel, useCoupon, getByTel } from '@/api/user.js'
export default {
  name: 'coupon',
  data () {
    const item = {
      c_no: 1,
      price: 200,
      use_condition: '满1799可用',
      c_name: 'E查工程VIP代金券',
      description: '只限VIP会员开通和续费使用',
      usable_begin: '2021-06-20',
      usable_end: '2022-06-20'
    }
    return {
      dataList: Array(10).fill(item),
      latestDataList: [],
      latestPage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      usedDataList: [],
      usedPage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      overdueDataList: [],
      overduePage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      activeName: 'latest'
    }
  },
  mounted () {
    this.loadDataLastest()
    this.loadDataUsed()
    this.loadDataOverdue()
  },
  methods: {
    headerColor: function ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    // 翻页时触发
    currentChangeLastest (currentPage) {
      this.latestPage.currentPage = currentPage
      this.loadDataLastest()
    },
    // 翻页时触发
    currentChangeUsed (currentPage) {
      this.usedPage.currentPage = currentPage
      this.loadDataUsed()
    },
    // 翻页时触发
    currentChangeOverdue (currentPage) {
      this.overduePage.currentPage = currentPage
      this.loadDataOverdue()
    },
    loadDataLastest () {
      const latestData = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        pageNum: this.latestPage.currentPage,
        stripNum: this.latestPage.pagerCount,
        couponStatuses: '0,3'
      }
      getCouponsByModel(latestData).then(result => {
        if (result.code === 0) {
          const arr = []
          result.data.list.forEach(item => {
            item.blCouponRule.couponExplain = JSON.parse(item.blCouponRule.couponExplain)
            arr.push(item)
          })
          this.latestDataList = arr
          this.latestPage.total = result.data.total
        } else {
          this.latestDataList = null
          ElMessage.error({
            message: result.msg,
            offset: 60
          })
        }
      }).catch(error => {
        ElMessage.error({
          message: error,
          offset: 60
        })
      })
    },
    loadDataUsed () {
      const usedData = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        pageNum: this.usedPage.currentPage,
        stripNum: this.usedPage.pagerCount,
        couponStatuses: '1'
      }
      getCouponsByModel(usedData).then(result => {
        if (result.code === 0) {
          const arr = []
          result.data.list.forEach(item => {
            item.blCouponRule.couponExplain = JSON.parse(item.blCouponRule.couponExplain)
            arr.push(item)
          })
          this.usedDataList = arr
          this.usedPage.total = result.data.total
        } else {
          this.usedDataList = null
          ElMessage.error({
            message: result.msg,
            offset: 60
          })
        }
      }).catch(error => {
        ElMessage.error({
          message: error,
          offset: 60
        })
      })
    },
    loadDataOverdue () {
      const overdueData = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        pageNum: this.overduePage.currentPage,
        stripNum: this.overduePage.pagerCount,
        couponStatuses: '2'
      }
      getCouponsByModel(overdueData).then(result => {
        if (result.code === 0) {
          const arr = []
          result.data.list.forEach(item => {
            item.blCouponRule.couponExplain = JSON.parse(item.blCouponRule.couponExplain)
            arr.push(item)
          })
          this.overdueDataList = arr
          this.overduePage.total = result.data.total
        } else {
          this.overdueDataList = null
          ElMessage.error({
            message: result.msg,
            offset: 60
          })
        }
      }).catch(error => {
        ElMessage.error({
          message: error,
          offset: 60
        })
      })
    },
    handleUseCoupon (couponId) {
      const vm = this
      const data = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        couponId: couponId
      }
      useCoupon(data).then(result => {
        if (result.code === 0) {
          ElMessage.success({
            message: '您已拥有权限',
            offset: 60
          })
          getByTel({ tel: JSON.parse(this.$store.getters.userInfo).mobile }).then(result => {
            if (result.code === 0) {
              vm.$store.dispatch('setVipData', JSON.stringify(result.data))
            }
          }).catch(error => {
            console.log(error)
          })
          this.loadDataLastest()
          this.loadDataUsed()
          this.loadDataOverdue()
        } else {
          ElMessage.error({
            message: result.msg,
            offset: 60
          })
        }
      }).catch(error => {
        ElMessage.error({
          message: error,
          offset: 60
        })
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 120px;
}

.c-container {
  column-gap: 10px;
  column-count: 2;
}

.c-card-box {
  display: flex;
  justify-content: center;
  width: 416px;
  height: 119px;
  margin-right: 20px;
  margin-bottom: 20px;
  break-inside: avoid;
}

.c-card-left-blue {
  width: 151px;
  height: 119px;
  background-image: url('../../assets/images/coupon-blue.png');
}

.c-card-left-gray {
  width: 151px;
  height: 119px;
  background-image: url('../../assets/images/coupon-gray.png');
}

.c-card-left-yellow {
  width: 151px;
  height: 119px;
  background-image: url('../../assets/images/coupon-yellow.png');
}

.c-price {
  font-size: 21px;
  color: #FFFFFF;
  margin-top: 26px;
  font-family: SourceHanSansCN-Regular;
}

.c-condition {
  font-size: 18px;
  color: #FFFFFF;
  margin-top: 10px;
  font-family: SourceHanSansCN-Regular;
}

.c-card-right {
  width: 265px;
  height: 119px;
  background-image: url('../../assets/images/coupon-right.png');
  position: relative;
  text-align: left;
}

.c-name {
  font-size: 18px;
  color: #333333;
  margin-top: 12px;
  margin-left: 16px;
  font-family: SourceHanSansCN-Regular;
}

.c-usable-time {
  font-size: 12px;
  color: #A6A6A6;
  margin-top: 30px;
  margin-left: 16px;
  font-family: SourceHanSansCN-Regular;
}

.c-description {
  font-size: 14px;
  padding: 5px 20px;
  padding-left: 20px;
  padding-top: 5px;
  color: #A6A6A6;
  display: flex;
  justify-content: space-between;
  cursor: pointer
}

.ac-page {
  display: flex;
  justify-content: flex-end;
  margin: 20px auto;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
</style>
